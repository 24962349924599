import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image, Link } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>
        Services | Anytime Fitness
        </title>
        <meta name={"description"} content={"Elevate Your Fitness Experience"} />
        <meta
          property={"og:title"}
          content={
            "Services | Anytime Fitness"
          }
        />
        <meta
          property={"og:description"}
          content={"Elevate Your Fitness Experience"}
        />
        <meta
          property={"og:image"}
          content={"https://orimonetwo.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://orimonetwo.com/img/3007654.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://orimonetwo.com/img/3007654.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://orimonetwo.com/img/3007654.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://orimonetwo.com/img/3007654.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://orimonetwo.com/img/3007654.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://orimonetwo.com/img/3007654.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://orimonetwo.com/img/1.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="800px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h1" font="--headline2">
          Our Offerings
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="50px"
            width="100%"
          />
          <Text font="--base" text-align="center">
          At Anytime Fitness, we are committed to providing an array of offerings designed to meet your fitness goals and enhance your overall well-being. Our comprehensive offerings ensure that you have everything you need to succeed, whether you're looking to build strength, improve endurance, or find balance and flexibility.
          </Text>
        </Box>
      </Section>
      <Section
        background="#ffffff"
        padding="90px 0 110px 0"
        quarkly-title="Team-12"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          flex-direction="column"
          lg-width="100%"
          grid-template-columns="repeat(3, 1fr)"
          grid-template-rows="repeat(2, 1fr)"
          grid-gap="46px 24px"
          sm-grid-template-columns="1fr"
          sm-grid-template-rows="auto"
          sm-grid-gap="36px 0"
          margin="50px 0px 0px 0px"
          lg-grid-template-columns="repeat(2, 1fr)"
          lg-grid-template-rows="auto"
        >
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://orimonetwo.com/img/3.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Personal Training
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Customized Workouts: Tailored exercise plans that match your specific fitness goals and current level.
                <br />
                <br />
                One-on-One Sessions: Individual attention from expert trainers to ensure you get the most out of every workout.
                <br />
                <br />
                Progress Tracking: Regular assessments to monitor your progress and adjust your program as needed.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://orimonetwo.com/img/4.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Group Fitness Classes
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Variety of Classes: From high-intensity interval training to calming yoga, there's a class for everyone.
                <br />
                <br />
                Certified Instructors: Experienced instructors lead each class, ensuring proper technique and safety.
                <br />
                <br />
                Community Engagement: Enjoy a motivating and supportive group environment that keeps you accountable.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://orimonetwo.com/img/5.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Nutritional Guidance
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Diet Planning: Personalized meal plans to complement your fitness regimen and help you achieve your goals.
                <br />
                <br />
                Nutritional Workshops: Interactive sessions that educate you on healthy eating habits and lifestyle choices.
                <br />
                <br />
                Ongoing Support: Continuous support from our nutrition experts to keep you on track.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://orimonetwo.com/img/6.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Advanced Fitness Equipment
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Cardio Machines: State-of-the-art treadmills, ellipticals, and stationary bikes for effective cardiovascular workouts.
                <br />
                <br />
                Strength Training Equipment: A full range of free weights, machines, and resistance bands to build muscle.
                <br />
                <br />
                Functional Training Zone: Space dedicated to functional exercises that improve overall body strength and coordination.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://orimonetwo.com/img/7.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Wellness Services
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Massage Therapy: Professional massage services to aid recovery and reduce stress.
                <br />
                <br />
                Sauna and Steam Room: Relax and rejuvenate after a workout with our luxurious sauna and steam facilities.
                <br />
                <br />
                Health Assessments: Comprehensive health checks to help you understand your fitness level and track improvements.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-width="100%"
              sm-margin="0px 0px 0 0px"
            >
              <Image
                src="https://orimonetwo.com/img/8.jpg"
                display="block"
                width="100%"
                border-radius="25px"
                lg-max-width="100%"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
                md-height="250px"
                md-width="auto"
                sm-width="100%"
                sm-height="auto"
                height="250px"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              sm-width="100%"
              sm-padding="0px 0px 0px 0"
              margin="25px 0px 0px 0px"
            >
              <Text
                color="--dark"
                font="600 18px --fontFamily-sans"
                lg-margin="0 0px 0px 0px"
                margin="0 0px 0 0px"
              >
                Comprehensive Fitness Solutions
              </Text>
              <Text
                margin="8px 0px 0px 0px"
                color="--greyD2"
                font="300 18px/140% --fontFamily-sansHelvetica"
              >
                Anytime Fitness is more than just a place to work out, it's a fitness sanctuary equipped with the best tools and expertise to guide you on your journey. Our offerings are tailored to suit all fitness levels, ensuring that every member finds something that resonates with their personal goals. With our dedicated team and top-notch facilities, we make it easier for you to stay motivated and on track.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Box
          padding="80px 72px 80px 72px"
          background="#dddddd"
          border-radius="24px"
          margin="0px 0px 64px 0px"
          sm-padding="60px 36px 60px 36px"
        >
          <Box
            width="100%"
            display="flex"
            flex-direction="column"
            md-width="100%"
            md-align-items="center"
            md-justify-content="center"
            md-text-align="center"
            lg-width="100%"
          >
            <Text
              margin="0px 0px 16px 0px"
              font="--headline2"
              color="--dark"
              md-text-align="center"
              sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            >
              Contact Us for More Information
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--base"
              color="--greyD3"
              md-text-align="center"
            >
              We are here to answer any questions you may have and to help you get started on your fitness journey. Whether you want more details about our offerings, need to schedule a consultation, or simply want to take a tour of our facilities, please reach out to us.
            </Text>
          </Box>
          <Link
            href="/contacts"
            color="#000000"
            padding="11px 45px 12px 45px"
            background="--color-primary"
            margin="50px 0px 0px 0px"
            display="inline-block"
            border-radius="8px"
          >
            {" "}
            Contact Us
          </Link>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
